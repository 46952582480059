//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_AppBtn
type X = MyComponentTypes<T>
type C = T["AppBtn"]

export type T_AppBtn = T

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class AppBtn extends HTMLElement {
	private Component: X["AppBtn"]
	private Button: X["Button"]
	private Span: X["Span"]

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Button = $<T["Button"]>(this, "button")[0]
		this.Span = $<T["Span"]>(this, "span")[0]
	}

	//* ---------- Exposed Methods -----------------------------------------------

	setLoading() {
		this.Component.setAttribute("d_loading", true)
		this.Button.disabled = true
	}

	reset() {
		this.Component.removeAttribute("d_loading")
		this.Button.disabled = false
	}

	set text(text: string) {
		this.Span.innerText = text
	}

	set disabled(value: boolean) {
		this.Button.disabled = value
	}
}

export default function AppBtnInit() {
	defineComponent<C>("app-btn", AppBtn)
}
