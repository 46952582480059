import { $ } from "./selectors.js";
/* Typed Form Data
-----------------------------------------------*/
export function TypedFormData(form) {
    return new FormData(form || undefined);
}
/* Typed Form Inputs
-----------------------------------------------*/
export function TypedFormInputs(form) {
    return Object.freeze({
        set,
        getInputElementsByName
    });
    function set(key, value) {
        const input = $(form, `[name="${key}"]`)[0];
        input.value = value;
    }
    function getInputElementsByName(key) {
        return $(form, `[name="${key}"]`);
    }
}
/* Typed URL Search Params
-----------------------------------------------*/
export function TypedURLSearchParams(init) {
    return new URLSearchParams(init);
}
