//* #region ----------------------------------------------- Component Imports

import LazyImageInit from "@WebComponents/LazyImage/LazyImage.js"
import IframePlayerInit from "@WebComponents/IframePlayer/IframePlayer.js"
import UpcomingShowsInit from "@WebComponents/UpcomingShows/UpcomingShows.js"
import MyOverlayInit from "@WebComponents/MyOverlay/MyOverlay.js"
import AppBtnInit from "@WebComponents/AppBtn/AppBtn.js"
import ContactFormInit from "@WebComponents/ContactForm/ContactForm.js"
import MyInputInit from "@WebComponents/MyInput/MyInput.js"
import MyNavInit from "@WebComponents/MyNav/MyNav.js"

//* #endregion ----------------------------------------------- Component Imports

export default function makeAppComponents() {
	//* ---------- Custom Web Components -----------------------------------------------

	MyNavInit()
	LazyImageInit()
	IframePlayerInit()
	UpcomingShowsInit()
	MyOverlayInit()
	AppBtnInit()
	MyInputInit()
	ContactFormInit()

	//* ---------- Component Factories -----------------------------------------------

	//* ---------- Exposed Factories -----------------------------------------------

	return Object.freeze({})
}
