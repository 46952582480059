//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_MyNav

type X<T> = MyComponentTypes<T>
type C = T["MyNav"]

type Routes = Omit<RenderedRoutes, "/404">

export type T_MyNav = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, defineComponent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class MyNav extends HTMLElement {
	private Hamburger: X<T>["Hamburger"]
	private Nav: X<T["Nav"]>["Nav"]
	private NavItems: X<T["Nav"]>["NavItem"][]

	constructor() {
		super()

		this.Hamburger = $<T["Hamburger"]>(this, ".hamburger")[0]
		this.Nav = $<T["Nav"]["Nav"]>(this, "nav")[0]
		this.NavItems = $<T["Nav"]["NavItem"]>(this.Nav, ".nav-item")

		this._init()
	}

	//* ---------- Initialize -----------------------------------------------

	private _init() {
		this.Hamburger.addEventListener("click", () => {
			this.Hamburger.toggleAttribute("d_selected")
			this.Nav.toggleAttribute("d_selected")
		})
	}

	//* ---------- Exposed Methods -----------------------------------------------

	updateNav() {
		const currentPath = window.location.pathname as keyof Routes
		const TargetLink = this._findLinkByPath(currentPath)
		if (!TargetLink) return
		TargetLink.setAttribute("d_selected", true)
		TargetLink.removeAttribute("href")
	}

	//* ---------- Helpers -----------------------------------------------

	private _findLinkByPath(path: keyof Routes) {
		return this.NavItems.find((Item) => Item.getAttribute("href") === path)
	}
}

export default function MyNavInit() {
	defineComponent<C>("my-nav", MyNav)
}
