//* #region ----------------------------------------------- Types

import type { AppEvent } from "haq"
import type { GlobalActions } from "./events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDocument.js"
import { $ } from "haq"
import makeAppComponents from "../components/index.js"
import EVENT_ROUTER from "./event-router.js"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

function makeApp() {
	let APP_COMPONENTS: ReturnType<typeof makeAppComponents>

	window.addEventListener("view-transition-end", () => {
		_updateNav()
	})

	_init()

	return Object.freeze({
		resize,
		executeEvent,
		getAppComponents
	})

	function _init() {
		APP_COMPONENTS = makeAppComponents()
		_updateNav()
	}

	function _updateNav() {
		const MyNav = $<MU_MyNav["MyNav"]>(document, "my-nav")[0]
		MyNav.updateNav()
	}

	function resize() {
		const vh = window.innerHeight / 100
		RootDoc.style.setProperty("--_vh", `${vh}px`)
	}

	function executeEvent(detail: AppEvent<GlobalActions>["detail"]) {
		const event = EVENT_ROUTER.get(detail.action)
		event.execute(detail.data)
	}

	function getAppComponents() {
		return APP_COMPONENTS
	}
}

const App = makeApp()
export default App
