//* #region ----------------------------------------------- Types
//* #endregion ----------------------------------------------- Types
//* ---------- Create Component Event -----------------------------------------------
export function makeComponentEvent({ triggerEl, eventName, detail, bubbles = false }) {
    const ev = new CustomEvent(eventName, {
        bubbles,
        detail
    });
    return Object.freeze({
        emit
    });
    function emit() {
        triggerEl.dispatchEvent(ev);
    }
}
//* ---------- Component Event Listener -----------------------------------------------
export function makeComponentEventListener(el) {
    return Object.freeze({
        add
    });
    function add(type, listener, options) {
        const eventListener = (event) => {
            listener(event);
        };
        el.addEventListener(type, eventListener, options);
    }
}
