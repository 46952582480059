//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_UpcomingShows & {
	pastShows: MU_PastShows
}

type X = MyComponentTypes<T>
type C = T["UpcomingShows"]

export type T_UpcomingShows = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, _, createClone, defineComponent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class UpcomingShows extends HTMLElement {
	private Table: X["Table"]
	private PastShowsWrapper: MyComponentTypes<T["pastShows"]>["PastShows"]
	private NoShowsTemplate: X["NoShowsTemplate"]

	constructor() {
		super()

		this.Table = $<T["Table"]>(this, "[x_selector='upcomingShowsTable']")[0]
		this.PastShowsWrapper = _<T["pastShows"]["PastShows"]>("pastShows") //!parent selector is not within scope (document)
		this.NoShowsTemplate = $<T["NoShowsTemplate"]>(this, "[x_selector='noShowsTemplate']")[0]

		this._init()
	}

	//* ---------- Initialize -----------------------------------------------

	private _init() {
		const UpdatedPastRows: MyComponentTypes<T["ConcertRow"]>["ConcertRow"][] = []
		const Rows = $<T["ConcertRow"]["ConcertRow"]>(this.Table, "tr")
		for (const Row of Rows) {
			const RowInfo = $<T["ConcertRow"]["Info"]>(Row, "concert-info")[0]
			const date = RowInfo.getAttribute("d_date")
			if (!date) continue
			if (new Date(date) <= new Date()) UpdatedPastRows.push(Row)
		}

		if (UpdatedPastRows.length > 0) this._appendPastShows(UpdatedPastRows)

		if ($<T["ConcertRow"]["ConcertRow"]>(this.Table, "tr").length === 0) this._appendNoShows()
	}

	//* ---------- DOM Creation -----------------------------------------------

	private _appendPastShows(Shows: MyComponentTypes<T["ConcertRow"]>["ConcertRow"][]) {
		for (const Show of Shows) {
			const RowInfo = $<T["ConcertRow"]["Info"]>(Show, "concert-info")[0]
			const date = RowInfo.getAttribute("d_date")
			if (!date) continue
			const venue = RowInfo.getAttribute("d_venue")
			if (!venue) continue
			const location = RowInfo.getAttribute("d_location")
			if (!location) continue
			this.PastShowsWrapper.prepend(this._createPastShow(date, venue, location))
			Show.remove()
		}
	}

	private _createPastShow(date: string, venue: string, location: string) {
		const PastShowClonable = $<T["pastShows"]["PastShow"]["PastShow"]>(
			this.PastShowsWrapper,
			"[x_selector='PastShow']"
		)[0]
		const Clone = createClone<T["pastShows"]["PastShow"]["PastShow"]>(PastShowClonable, true)
		const DateEl = $<T["pastShows"]["PastShow"]["Date"]>(Clone, "[x_selector='date']")[0]
		const VenueEl = $<T["pastShows"]["PastShow"]["Venue"]>(Clone, "[x_selector='venue']")[0]
		const LocationEl = $<T["pastShows"]["PastShow"]["Location"]>(Clone, "[x_selector='location']")[0]
		DateEl.innerText = this._formatPastDate(new Date(date))
		VenueEl.innerText = venue
		LocationEl.innerHTML = location
		return Clone
	}

	private _appendNoShows() {
		const clone = createClone(this.NoShowsTemplate.content, true)
		this.Table.append(clone)
	}

	//* ---------- Date Helpers -----------------------------------------------

	private _formatPastDate(date: Date) {
		const newDate = new Date(date)
		const month = newDate.getMonth()
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December"
		]
		const day = newDate.getDate()
		const year = newDate.getFullYear()
		return `${months[Number(month)]} ${day}, ${year}`
	}
}

export default function UpcomingShowsInit() {
	defineComponent<C>("upcoming-shows", UpcomingShows)
}
