//* #endregion ----------------------------------------------- Types
import { makeAppEvents } from "../DOM_Events/app-events.js";
export function makeRequest() {
    const EventEmitter = makeAppEvents();
    return Object.freeze({
        getPostResponse,
        getPartial,
        goToLocalURL,
        uploadToServer,
        uploadToCDN
    });
    async function getPostResponse(url, payload) {
        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        };
        try {
            const response = await fetch(url, options);
            return (await response.json());
        }
        catch (err) {
            console.error(err);
            return {
                success: false,
                error: "An unexpected error occured<br>Please check your internet connection"
            };
        }
    }
    async function getPartial(url, payload) {
        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        };
        try {
            const response = await fetch(url, options);
            if (response.headers.get("Content-Type")?.includes("application/json")) {
                return (await response.json());
            }
            return await response.text();
        }
        catch (err) {
            console.error(err);
            return {
                success: false,
                error: "An unexpected error occured<br>Please check your internet connection"
            };
        }
    }
    function goToLocalURL(route) {
        window.location.href = route;
    }
    async function uploadToServer(route, file, formData, uID) {
        return await new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open("POST", route, true);
            req.upload.onprogress = (e) => {
                const size = file.size;
                if (e.loaded <= size) {
                    const perc = Math.round((e.loaded / size) * 100);
                    EventEmitter.emit({
                        action: "file-upload-progress-received",
                        data: { perc, uID }
                    });
                }
                else {
                    EventEmitter.emit({
                        action: "file-upload-progress-received",
                        data: { perc: 100, uID }
                    });
                }
            };
            req.onload = () => {
                if (req.status === 0 || (req.status >= 200 && req.status < 400)) {
                    return resolve(JSON.parse(req.response));
                }
                return reject(req.response.error);
            };
            req.onerror = (error) => {
                return reject(new Error(error));
            };
            req.send(formData);
        });
    }
    async function uploadToCDN(file, PATH, ACCESS_KEY, uID) {
        return await new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open("PUT", PATH, true);
            req.setRequestHeader("Content-Type", "application/octet-stream");
            req.setRequestHeader("AccessKey", ACCESS_KEY);
            req.upload.onprogress = (e) => {
                const size = file.size;
                if (e.loaded <= size) {
                    const perc = Math.round((e.loaded / size) * 100);
                    EventEmitter.emit({
                        action: "file-upload-progress-received",
                        data: { uID, perc }
                    });
                }
            };
            req.onload = () => {
                //* FINISHED -> SUCCESS OR FAIL
                const response = req.response;
                if (req.status === 0 || (req.status >= 200 && req.status < 400)) {
                    resolve(true);
                }
                else {
                    return reject(new Error(response));
                }
            };
            req.onerror = (error) => {
                return reject(new Error(error));
            };
            req.send(file);
        });
    }
}
