//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"
import type { T_MyInput } from "../MyInput/MyInput.js"

type T = MU_ContactForm & {
	myInputEvents: T_MyInput["events"]
	events: {
		"contact-form-submitted": {
			Form: X["Form"]
			SubmitBtn: X["SubmitBtn"]
		}
	}
}

type X = MyComponentTypes<T>
type C = T["ContactForm"]
type E = T["events"]
type F = T["FormData"]
type InputWrapper = MyComponentTypes<T["InputWithLabel"]>["InputWithLabel"]

export type T_ContactForm = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { assertCannotReach } from "front-end-helpers"
import { $, createClone, defineComponent, makeComponentEventListener } from "haq"
import { makeAppEvents } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class ContactForm extends HTMLElement {
	private InputWrappers: InputWrapper[]
	private Form: X["Form"]
	private SubmitBtn: X["SubmitBtn"]

	private FormEvent: ReturnType<typeof makeAppEvents<E>>

	constructor() {
		super()

		this.InputWrappers = $<T["InputWithLabel"]["InputWithLabel"]>(this, "[x_selector='inputWithLabel']")
		this.Form = $<T["Form"]>(this, "form")[0]
		this.SubmitBtn = $<T["SubmitBtn"]>(this, "[x_selector='submitBtn']")[0]
		this.FormEvent = makeAppEvents<E>()
		this._init()
	}

	//* ---------- Initialize -----------------------------------------------

	private _init() {
		for (const Wrapper of this.InputWrappers) {
			const errorEventListener = makeComponentEventListener<T["myInputEvents"]>(Wrapper)
			errorEventListener.add("validation-state-changed", (e) => {
				if (e.detail.isInvalid) this._showError(Wrapper, e.detail.name)
				else this._hideError(Wrapper)
			})
		}

		this.Form.addEventListener("submit", (e) => {
			e.preventDefault()
			if (!this._isValid()) return
			this.FormEvent.emit({
				action: "contact-form-submitted",
				data: {
					Form: this.Form,
					SubmitBtn: this.SubmitBtn
				}
			})
		})
	}

	//* ---------- Form Validation -----------------------------------------------

	private _isValid() {
		let isValid = true
		const Inputs = $<T["EmailInput"]>(this.Form, ".input-field")
		Inputs.every((Input) => {
			if (!Input.checkValidity()) {
				Input.focus()
				isValid = false
				return false
			}
			return true
		})
		return isValid
	}

	//* ---------- Show/Hide Error -----------------------------------------------

	private _showError(Wrapper: InputWrapper, inputName: string) {
		const ErrorEl = $<T["InputWithLabel"]["InputError"]>(Wrapper, "[x_selector='input-error']")[0]
		if (ErrorEl) return

		const Template = $<T["InputWithLabel"]["InputErrorTemplate"]>(Wrapper, "[x_selector='inputErrorTemplate']")[0]
		const Clone = createClone(Template.content, true)
		const CloneContent = $<T["InputWithLabel"]["InputError"]>(Clone, "[x_selector='input-error']")[0]
		const errorText = this._getErrorByInputType(inputName as keyof F)
		if (!errorText) return
		CloneContent.innerText = errorText
		Wrapper.append(Clone)
	}

	private _hideError(Wrapper: InputWrapper) {
		const ErrorEl = $<T["InputWithLabel"]["InputError"]>(Wrapper, "[x_selector='input-error']")[0]
		if (ErrorEl) ErrorEl.remove()
	}

	//* ---------- Error messages -----------------------------------------------

	private _getErrorByInputType(type: keyof F) {
		switch (type) {
			case "email":
				return "Pleaase enter a valid email address"
			case "fullname":
				return "Pleaase enter your full name"
			case "message":
				return "Pleaase enter a message"
			default:
				assertCannotReach(type)
		}
	}
}

export default function ContactFormInit() {
	defineComponent<C>("contact-form", ContactForm)
}
