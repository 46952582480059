export function makeAppEvents() {
    return Object.freeze({
        emit
    });
    function emit(args) {
        const { action, data } = args;
        const ev = new CustomEvent("app-event", {
            detail: {
                action,
                data
            }
        });
        window.dispatchEvent(ev);
    }
}
