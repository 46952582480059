//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_MyOverlay

type X = MyComponentTypes<T>
type C = T["MyOverlay"]

export type T_MyOverlay = T

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDocument.js"
import { $, TypedComponent, defineComponent } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class MyOverlay extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Content: X["Content"] | undefined
	private CloseBtn: X["CloseBtn"] | undefined

	private _keyUpHandler

	private REDIRECT_URL: keyof RenderedRoutes | null = null
	private IS_ESCAPABLE = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Content = $<T["Content"]>(this, ".overlay-content")[0]
		this.CloseBtn = $<T["CloseBtn"]>(this, ".close-btn")[0]

		this.Component.classList.remove("no-display")

		this._keyUpHandler = this._onKeyUp.bind(this)
		this._init()
	}

	//* ---------- Initialize -----------------------------------------------

	private _init() {
		const closeAble = [this, this.CloseBtn]
		for (const El of closeAble) {
			El?.addEventListener("click", (e) => this._onClose(e))
		}
	}

	//* ---------- Exposed Methods -----------------------------------------------

	show() {
		this.Component.setAttribute("d_visible", true)
		RootDoc.classList.add("no-scroll")
		if (this.IS_ESCAPABLE) document.addEventListener("keyup", this._keyUpHandler)
	}

	hide() {
		if (this.REDIRECT_URL) {
			window.location.href = this.REDIRECT_URL
			return
		}
		this.Component.removeAttribute("d_visible")
		RootDoc.classList.remove("no-scroll")
		if (this.IS_ESCAPABLE) document.removeEventListener("keyup", this._keyUpHandler)
	}

	addEscapeListener() {
		this.IS_ESCAPABLE = true
	}

	//* ---------- Getters/Setters -----------------------------------------------

	set redirect(url: keyof RenderedRoutes) {
		this.REDIRECT_URL = url
	}

	//* ---------- Listeners -----------------------------------------------

	private _onClose(e: Event) {
		const target = e.target
		if (target === e.currentTarget) this.hide()
	}

	private _onKeyUp(e: KeyboardEvent) {
		if (e.key === "Escape") this.hide()
	}

	//* ---------- DOM Rendering -----------------------------------------------

	render(content: DocumentFragment) {
		if (!this.Content) return
		this.Content.replaceChildren()
		this.Content.append(content)
		this.show()
	}
}

export default function MyOverlayInit() {
	defineComponent<C>("my-overlay", MyOverlay)
}
