//* #region ----------------------------------------------- Types

type T = MU_ServerResultOverlay

type ShowResponseArgs = {
	resp: ErrorResponse | { success: true }
	respTitle: string
	respText: string
	onRedirect?: keyof RenderedRoutes
}

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, _ } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export default function makeServerResponse() {
	const Overlay = _<T["ServerResultOverlay"]>("serverResultOverlay")
	const Heading = $<T["Heading"]>(Overlay, "h2")[0]
	const ResultIcon = $<T["MyIcon"]>(Overlay, "[d_icon='success']")[0] || $<T["MyIcon"]>(Overlay, "[d_icon='error']")[0]
	const Message = $<T["Message"]>(Overlay, "p")[0]
	const Button = $<T["OkBtn"]>(Overlay, "[x_selector='okBtn']")[0]

	return Object.freeze({
		show
	})

	function show({ ...args }: Readonly<ShowResponseArgs>) {
		Heading.innerText = args.respTitle
		if (!args.resp.success) Heading.classList.add("clr-error")
		else Heading.classList.remove("clr-error")

		ResultIcon.setAttribute("d_icon", args.resp.success ? "success" : "error")

		const textHTML = args.resp.success || args.resp.error ? args.respText : "A server-side error occured"
		Message.innerHTML = textHTML
		if (!args.resp.success) Message.classList.add("clr-error")
		else Message.classList.remove("clr-error")

		if (args.onRedirect) Overlay.redirect = args.onRedirect
		Button.addEventListener("click", () => Overlay.hide())
		Overlay.show()
	}
}
