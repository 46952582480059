//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_LazyImage
type X = MyComponentTypes<T>
type C = T["LazyImage"]

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class LazyImage extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Img: X["Img"]

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Img = $<T["Img"]>(this, "img")[0]
		if (this.Img.complete) {
			this._onLoaded()
			return
		}
		this.Img.addEventListener("load", this._onLoaded.bind(this))
	}

	private _onLoaded() {
		this.Component.setAttribute("d_loaded", true)
	}
}

export default function LazyImageInit() {
	defineComponent<C>("lazy-image", LazyImage)
}
