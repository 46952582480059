//* #region ----------------------------------------------- Types
//* #endregion ----------------------------------------------- Types
/* Selectors
-----------------------------------------------*/
export function $(parent, selector) {
    return Array.from(parent.querySelectorAll(selector));
}
export function _(id) {
    return document.getElementById(id);
}
export function getSibling(el, order) {
    return order === "previous"
        ? el.previousElementSibling
        : el.nextElementSibling;
}
export function getClosest(el, selector) {
    return el.closest(selector);
}
export function getChildren(el) {
    return Array.from(el.children);
}
export function getTypedComputedStyle(el) {
    return getComputedStyle(el);
}
/* Typed Component
-----------------------------------------------*/
export function TypedComponent(el) {
    return el;
}
