//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_IframePlayer
type X = MyComponentTypes<T>
type C = T["IframePlayer"]

export type T_IframePlayer = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, createClone, defineComponent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class IframePlayer extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private ThumbWrapper: X["ThumbWrapper"]
	private IframeTemplate: X["IframeTemplate"]

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.ThumbWrapper = $<T["ThumbWrapper"]>(this, ".thumb-wrapper")[0]
		this.IframeTemplate = $<T["IframeTemplate"]>(this, "[x_selector='iframeTemplate']")[0]
		this._init()
	}

	private _init() {
		this.Component.addEventListener("click", () => {
			const src = this.Component.getAttribute("d_src")
			if (!src) return
			this.replaceChild(this._createIframe(src), this.ThumbWrapper)
		})
	}

	private _createIframe(src: string) {
		const Clone = createClone(this.IframeTemplate.content, true)
		const Iframe = $<T["Iframe"]>(Clone, "iframe")[0]
		Iframe.src = src
		return Iframe
	}
}

export default function IframePlayerInit() {
	defineComponent<C>("iframe-player", IframePlayer)
}
