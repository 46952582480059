//* #region ----------------------------------------------- Types

import type { GlobalActions } from "./events.js"

type ExecutedAction<T extends keyof GlobalActions> = Readonly<{
	execute: (data: GlobalActions[T]) => void
}>

type Router = {
	[key in keyof GlobalActions]: ExecutedAction<key>
}

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only events)

import contactFormSubmitted from "../events/contact-form-submitted.js"

//* #endregion ----------------------------------------------- Module Imports (only events)

function makeEventRouter() {
	const router: Router = {
		"contact-form-submitted": contactFormSubmitted
	}

	return Object.freeze({
		get
	})

	function get<T extends keyof Router>(action: T) {
		return router[action]
	}
}

//* ---------- EXPORTS

const EVENT_ROUTER = makeEventRouter()

export default EVENT_ROUTER
