//* #region ----------------------------------------------- Types

import makeServerResponse from "@global/modals/render-server-response.js"
import type { GlobalActions } from "../init/events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import Request from "@helpers/request.js"
import { makeFormRequest } from "front-end-helpers"
import { TypedFormData } from "haq"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: async (data: GlobalActions["contact-form-submitted"]) => {
		const { Form, SubmitBtn } = data
		const formRequest = makeFormRequest({ Form, SubmitBtn })

		const FormData = TypedFormData<MU_ContactForm["FormData"]>(Form)
		const payload = {
			fullName: FormData.get("fullname")?.trim() || "",
			email: FormData.get("email")?.trim().toLowerCase() || "",
			message: FormData.get("message")?.trim() || ""
		}

		const response = await Request.getPostResponse("https://aminobelyamani.xyz/ig-contact", payload)

		const serverReponse = makeServerResponse()
		if (response.success) {
			formRequest.afterResponse(true)
			serverReponse.show({
				resp: response,
				respTitle: "Message Sent",
				respText: "Thank you, your message has been sent successfully"
			})
			return
		}

		formRequest.afterResponse(false)
		serverReponse.show({
			resp: response,
			respTitle: "Error",
			respText: response.error
		})
	}
}
