//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_MyInput & {
	events: {
		"validation-state-changed": {
			name: string
			isInvalid: boolean
		}
	}
}

type X = MyComponentTypes<T>
type C = T["MyInput"]
type E = T["events"]

export type T_MyInput = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent, makeComponentEvent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class MyInput extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Input: X["EmailInput"]

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Input = $<T["EmailInput"]>(this, ".input-field")[0]

		this._init()
	}

	//* ---------- Initialize -----------------------------------------------

	private _init() {
		this.Input.addEventListener("focus", () => this.Component.setAttribute("d_focus", true))
		this.Input.addEventListener("blur", () => {
			if (this.Input.value === "") this.Component.removeAttribute("d_focus")
		})
		this.Input.addEventListener("input", () => this._checkValidity())
		this.Input.addEventListener("focusout", () => this._checkValidity())
	}

	private _toggleInvalidState(isInvalid: boolean) {
		if (isInvalid) this.Component.setAttribute("d_invalid", true)
		else this.Component.removeAttribute("d_invalid")

		const event = makeComponentEvent<E>({
			triggerEl: this,
			eventName: "validation-state-changed",
			detail: {
				name: this.Input.name,
				isInvalid
			},
			bubbles: true
		})
		event.emit()
	}

	private _checkValidity() {
		if (!this.Input.checkValidity()) this._toggleInvalidState(true)
		else this._toggleInvalidState(false)
	}
}

export default function MyInputInit() {
	defineComponent<C>("my-input", MyInput)
}
